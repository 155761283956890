export function getSettingValue(setting, settingList) {
  const matchedSetting = settingList.filter(m => m.name === setting.name);
  if (matchedSetting.length > 0) {
    return matchedSetting[0];
  } else {
    return null;
  }
}

function getMatchedSettingGroup(groupName, categories) {
  const cleanedGroupName = groupName.toLowerCase().replace(/\s/g, "");
  const matchedSettingGroup = categories[0].settingGroups.filter(
    m => m.name === cleanedGroupName
  );
  if (matchedSettingGroup.length > 0) {
    return matchedSettingGroup[0];
  } else {
    return null;
  }
}

export function getSettingGroupId(groupName, categories) {
  if (categories) {
    const matchedSettingGroup = getMatchedSettingGroup(groupName, categories);
    if (matchedSettingGroup) {
      return matchedSettingGroup.id;
    }
  }
}

export function getSettingGroupDisplayName(groupName, categories) {
  if (categories) {
    const matchedSettingGroup = getMatchedSettingGroup(groupName, categories);
    if (matchedSettingGroup) {
      return matchedSettingGroup.displayName;
    }
  }
}
