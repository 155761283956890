import React from "react";
import PropTypes from "prop-types";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import DealerSettings from "../DealerSettings";
import ToastContainer from "@cx/ui/Toast";
import { getSettingNames } from "../../api/commonApi";
import { getDealerSettings, getUserSettings } from "../../api/settingsApi";
import UserSettings from "../UserSettings";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { getUsers } from "../../api/gatewayApi";
import {
  getUserSettingsTableList,
  matchDealerSettings
} from "../utils/mapperHelper";
import { hasSensitiveSettings, hasValue } from "../utils/commonHelper";
import { getSettingGroupDisplayName } from "../utils/settingHelper";

class HomePage extends React.Component {
  static propTypes = {
    bearerToken: PropTypes.string,
    categories: PropTypes.array,
    hideLeftNav: PropTypes.bool,
    match: PropTypes.object,
    queryParams: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      settingNames: [],
      dealerSettings: [],
      userSettings: [],
      groupId: this.props.match.params.groupId,
      loading: false,
      group: this.props.match.params.group,
      users: []
    };
  }

  componentDidMount() {
    this.loadData(this.state.groupId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.groupId !== prevProps.match.params.groupId) {
      this.loadData(this.props.match.params.groupId);
    }
  }
  settingType;

  loadData(groupId) {
    const { queryParams } = this.props;
    this.setState({ loading: true, group: this.props.match.params.group });
    Promise.all(
      [
        getSettingNames(groupId, this.props.bearerToken),
        getDealerSettings(groupId, queryParams, this.props.bearerToken),
        getUserSettings(groupId, queryParams, this.props.bearerToken),
        getUsers(queryParams, this.props.bearerToken)
      ].map(p => p.catch(() => undefined))
    ).then(results => {
      this.setState({
        settingNames: hasValue(results[0]) ? results[0].data.items : [],
        dealerSettings: matchDealerSettings(
          hasValue(results[0]) ? results[0].data.items : [],
          hasValue(results[1]) ? results[1].data.items : []
        ),
        userSettings: this.matchUserSettings(
          hasValue(results[0]) ? results[0].data.items : [],
          hasValue(results[2]) ? results[2].data.items : [],
          hasValue(results[3]) ? results[3].data : []
        ),
        users: hasValue(results[3]) ? results[3].data : [],
        loading: false,
        groupId
      });
    });
  }

  toggleClearSettings = (settingType, settingGroupId, toggle) => {
    if (settingType === "dealer") {
      Promise.all([
        getDealerSettings(
          this.state.groupId,
          this.props.queryParams,
          this.props.bearerToken,
          toggle
        )
      ]).then(response => {
        this.setState({
          dealerSettings: matchDealerSettings(
            this.state.settingNames,
            response[0].data.items
          )
        });
      });
    } else if (settingType === "user") {
      Promise.all([
        getUserSettings(
          this.state.groupId,
          this.props.queryParams,
          this.props.bearerToken,
          toggle
        )
      ]).then(response => {
        this.setState({
          userSettings: this.matchUserSettings(
            this.state.settingNames,
            response[0].data.items,
            this.state.users
          )
        });
      });
    }
  };

  getUserSettingNames() {
    return this.state.settingNames.filter(m => m.settingType === "user");
  }

  getDealerSettingNames() {
    return this.state.settingNames.filter(m => m.settingType === "dealer");
  }

  matchUserSettings(settingNames, userSettings, users) {
    const userSettingsNames = settingNames.filter(
      m => m.settingType === "user"
    );
    if (userSettingsNames.length > 0) {
      return getUserSettingsTableList(
        userSettingsNames,
        userSettings,
        users,
        this.props.queryParams.dealerId
      );
    }
    return [];
  }

  refreshDealerSettings = () => {
    getDealerSettings(
      this.state.groupId,
      this.props.queryParams,
      this.props.bearerToken
    ).then(response => {
      this.setState({
        dealerSettings: matchDealerSettings(
          this.state.settingNames,
          hasValue(response) ? response.data.items : []
        )
      });
    });
  };

  render() {
    const { loading } = this.state,
      groupName = getSettingGroupDisplayName(
        this.state.group,
        this.props.categories
      );
    return (
      <Grid fluid className="template-page-wrapper page-wrapper__content-only">
        <ToastContainer htmlId="EnterpriseSettingsToast" />
        <Col sm={12} md={12}>
          {loading ? (
            <div className="error-message">
              <LoadingIndicator htmlId="LoadingIndicatorExample" />
            </div>
          ) : (
            <div className="template-content-wrapper content-wrapper__content-only">
              {this.getDealerSettingNames().length > 0 ? (
                <DealerSettings
                  dealerSettings={this.state.dealerSettings}
                  queryParams={this.props.queryParams}
                  group={groupName}
                  settingGroupId={this.state.groupId}
                  bearerToken={this.props.bearerToken}
                  hasSensitiveSettings={hasSensitiveSettings(
                    this.getDealerSettingNames()
                  )}
                  toggleClearSettings={this.toggleClearSettings}
                  hideLeftNav={this.props.hideLeftNav}
                  refreshDealerSettings={this.refreshDealerSettings}
                />
              ) : (
                <React.Fragment>
                  {this.props.hideLeftNav ? (
                    <div id="header-no-padding">{this.state.group}</div>
                  ) : (
                    <div id="header">{this.state.group}</div>
                  )}
                  <div id="section-header">
                    <strong>Dealer Settings</strong>
                  </div>
                  <br />
                  No dealer settings configured for this settings group <br />{" "}
                  <hr />
                </React.Fragment>
              )}
              {this.getUserSettingNames().length > 0 ? (
                <UserSettings
                  userSettings={this.state.userSettings}
                  queryParams={this.props.queryParams}
                  group={groupName}
                  settingGroupId={this.state.groupId}
                  bearerToken={this.props.bearerToken}
                  hasSensitiveSettings={hasSensitiveSettings(
                    this.getUserSettingNames()
                  )}
                  toggleClearSettings={this.toggleClearSettings}
                />
              ) : (
                <React.Fragment>
                  <div id="user-settings-header">
                    <strong>User Settings</strong>
                  </div>
                  <br />
                  No user settings configured for this settings group <br />{" "}
                  <hr />
                </React.Fragment>
              )}
            </div>
          )}
        </Col>
      </Grid>
    );
  }
}

export default HomePage;
