import React from "react";
import PropTypes from "prop-types";
import { receiveUserJWT } from "../utils/communicationsHelper";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import SettingsFrame from "./SettingsFrame";

class MainFrame extends React.Component {
  static propTypes = {
    queryParams: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      bearerToken: ""
    };
  }

  componentDidMount() {
    this.getBearerToken();
  }

  getBearerToken() {
    if (this.props.queryParams.isLocal) {
      this.setState({ bearerToken: this.props.queryParams.bearerToken });
    } else {
      receiveUserJWT().then(response => {
        // if (!response || !response.accessToken || !response.expiresIn) {
        // }
        // Create JWT for use to use to call API's
        const jwt = {
          accessToken: response.accessToken,
          expires: new Date().getTime() + response.expiresIn * 1000
        };
        // Refresh token 5 minutes before it expires
        setTimeout(
          () => this.getBearerToken(),
          jwt.expires - new Date().getTime() - 5 * 60 * 1000
        );
        this.setState({ bearerToken: jwt.accessToken });
      });
    }
  }

  render() {
    if (this.state.bearerToken && this.state.bearerToken.length > 0) {
      return (
        <SettingsFrame
          {...this.props}
          queryParams={this.props.queryParams}
          bearerToken={this.state.bearerToken}
        />
      );
    }
    return <LoadingIndicator htmlId="LoadingIndicatorExample" />;
  }
}

export default MainFrame;
