import React from "react";
import PropTypes from "prop-types";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";

const Header = props => {
  Header.propTypes = {
    name: PropTypes.string
  };

  return (
    <div className="main-header">
      <Grid>
        <Row>
          <h2>{props.name}</h2>
        </Row>
      </Grid>
    </div>
  );
};
export default Header;
