import { settingsApiGet } from "./api-config-settings";

export function getSettingsCategories(bearerToken) {
  return settingsApiGet(`/api/settings/categories/withgroups`, bearerToken);
}

export function getSettingNames(settingGroupId, bearerToken) {
  return settingsApiGet(
    `/api/settings/names?settingGroupId=${settingGroupId}`,
    bearerToken
  );
}
