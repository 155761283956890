/* eslint-disable react/display-name */

import React from "react";
import { getSettingValue } from "./settingHelper";
import { getOrderedSettings } from "./commonHelper";

export function getUserSettingColumns() {
  return [
    {
      title: "User Full Name",
      dataIndex: "agentFullName"
    },
    {
      title: "User Settings",
      dataIndex: "settings",
      columnSortable: false,
      render: settings => (
        <div>
          {settings.map(function(userSetting, index) {
            const uniqueKey = index + 1;
            return (
              <React.Fragment key={uniqueKey}>
                <strong>{userSetting.displayName}</strong>{" "}
                {userSetting.value ? userSetting.value : ""}|
              </React.Fragment>
            );
          })}
        </div>
      )
    },
    {
      title: "Filters",
      dataIndex: "settingsFilter",
      className: "hide"
    }
  ];
}

function getUserSettingsByUserId(settingNames, matchedUserSettings, userId) {
  const settingsToReturn = settingNames.map(function(settingName) {
    const existingSetting = matchedUserSettings.find(
      m => m.name === settingName.name
    );
    return {
      settingId: existingSetting ? existingSetting.id : null,
      name: settingName.name,
      settingNameId: settingName.id,
      value: existingSetting ? existingSetting.value : "",
      displayType: settingName.displayType,
      displayName: settingName.displayName,
      userId,
      hasModified: false
    };
  });
  return getOrderedSettings(settingsToReturn);
}

function findUserSetting(userSettings, userId) {
  return userSettings.filter(m => m.userId === userId);
}

function getSettingsFilter(settingsList) {
  const settingValues = settingsList.map(function(setting) {
    return setting.value;
  });
  return settingValues.join(" ");
}

export function getUserSettingsTableList(
  settingNames,
  userSettings,
  userList,
  dealerId
) {
  return userList.map(function(user) {
    const settingsList = getUserSettingsByUserId(
      settingNames,
      findUserSetting(userSettings, user.UserId),
      user.UserId
    );

    return {
      agentFullName: user.FullName,
      userId: user.UserId,
      key: user.UserId,
      dealerId,
      settings: settingsList,
      settingsFilter: getSettingsFilter(settingsList)
    };
  });
}

export function getDealerSettingsList(settingNames, dealerSettings) {
  const settingsToReturn = settingNames.map(function(settingName) {
    const matchedSetting = getSettingValue(settingName, dealerSettings);
    return {
      settingId: matchedSetting ? matchedSetting.id : null,
      name: settingName.name,
      settingNameId: settingName.id,
      value: matchedSetting ? matchedSetting.value : "",
      displayType: settingName.displayType,
      displayName: settingName.displayName,
      hasModified: false
    };
  });
  return getOrderedSettings(settingsToReturn);
}

export function matchDealerSettings(settingNames, dealerSettings) {
  const dealerSettingsNames = settingNames.filter(
    m => m.settingType === "dealer"
  );
  if (dealerSettingsNames.length > 0) {
    return getDealerSettingsList(dealerSettingsNames, dealerSettings);
  }
  return [];
}
