import {
  settingsApiGet,
  settingsApiPut,
  settingsApiPost
} from "./api-config-settings";

export function getUserSettings(groupId, queryParams, bearerToken, decipher) {
  let url = `/api/settings/user?groupId=${groupId}&dealerId=${queryParams.dealerId}`;
  if (decipher) {
    url = url + `&decipher=${decipher}`;
  }
  return settingsApiGet(url, bearerToken);
}

export function updateUserSetting(setting, queryParams, bearerToken) {
  const data = {
    userId: setting.userId,
    dealerId: queryParams.dealerId,
    settingName: setting.name,
    settingValue: setting.value
  };
  return settingsApiPut(
    `/api/settings/user/id/${setting.settingId}`,
    data,
    bearerToken
  );
}

export function createUserSetting(setting, queryParams, bearerToken) {
  const data = {
    userId: setting.userId,
    dealerId: queryParams.dealerId,
    settingNameId: setting.settingNameId,
    settingValue: setting.value
  };
  return settingsApiPost(`/api/settings/user/`, data, bearerToken);
}

export function getDealerSettings(groupId, queryParams, bearerToken, decipher) {
  let url = `/api/settings/dealer?groupId=${groupId}&dealerId=${queryParams.dealerId}`;
  if (decipher) {
    url = url + `&decipher=${decipher}`;
  }
  return settingsApiGet(url, bearerToken);
}

export function createDealerSetting(setting, queryParams, bearerToken) {
  const data = {
    dealerId: queryParams.dealerId,
    settingNameId: setting.settingNameId,
    settingValue: setting.value
  };
  return settingsApiPost(`/api/settings/dealer`, data, bearerToken);
}

export function getCurrentDealerGroupBooleanSetting(
  settingName,
  settingValue,
  queryParams,
  bearerToken
) {
  const url = `/api/settings/dealer?settingName=${settingName}&settingValue=${settingValue}&dealerId=${queryParams.dealerId}`;
  return settingsApiGet(url, bearerToken);
}

export function updateDealerSetting(setting, queryParams, bearerToken) {
  const data = {
    dealerId: queryParams.dealerId,
    settingName: setting.name,
    settingValue: setting.value
  };
  return settingsApiPut(
    `/api/settings/dealer/id/${setting.settingId}`,
    data,
    bearerToken
  );
}
