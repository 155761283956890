import React from "react";
import { render } from "react-dom";
import "./index.scss";
import routes from "./routes";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

render(
  <Router className="main-layout" history={history}>
    {routes}
  </Router>,
  document.getElementById("root")
);
