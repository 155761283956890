import React from "react";
import PropTypes from "prop-types";
import ResponsiveAside from "./ResponsiveAside";
import { Route, Switch } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import HomePage from "../pages/HomePage";
import MainPage from "../pages/MainPage";
import { getSettingsCategories } from "../../api/commonApi";
import { ErrorPage } from "../pages/ErrorPage";
import Header from "../Header";

class SettingsFrame extends React.Component {
  static propTypes = {
    bearerToken: PropTypes.string,
    queryParams: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      groups: [],
      displayName: "",
      error: ""
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  categoryDisplayName;

  getCategories() {
    Promise.all([getSettingsCategories(this.props.bearerToken)])
      .then(response => {
        const categories = response[0].data.items;
        const filteredCategories = categories.filter(
          m => m.categoryName === this.props.queryParams.categoryName
        );
        if (filteredCategories.length > 0) {
          this.setState({
            categories: filteredCategories,
            displayName: filteredCategories[0].categoryDisplayName
          });
        } else {
          this.setState({ error: "Category doesn't exist." });
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401)
          this.setState({ error: "Authentication failed." });
      });
  }

  render() {
    const hideLeftNav = this.props.queryParams.groupName.length > 0;
    return (
      <div>
        {hideLeftNav ? (
          <div>
            <main>
              <Switch>
                <Route
                  path="/"
                  render={() => (
                    <LandingPage queryParams={this.props.queryParams} />
                  )}
                  exact
                />
                <Route
                  path="/main"
                  render={() => (
                    <MainPage
                      error={this.state.error}
                      queryParams={this.props.queryParams}
                      categories={this.state.categories}
                    />
                  )}
                  exact
                />
                <Route
                  path="/settings/:group/:groupId"
                  render={props => (
                    <HomePage
                      {...props}
                      queryParams={this.props.queryParams}
                      categories={this.state.categories}
                      bearerToken={this.props.bearerToken}
                      hideLeftNav
                    />
                  )}
                />
                <Route path="*" component={ErrorPage} />
              </Switch>
            </main>
          </div>
        ) : (
          <div>
            <Header name={this.state.displayName} />
            <div className="docFrame">
              <ResponsiveAside
                {...this.props}
                categories={this.state.categories}
              />
              <main>
                <Switch>
                  <Route
                    path="/"
                    render={() => (
                      <LandingPage queryParams={this.props.queryParams} />
                    )}
                    exact
                  />
                  <Route
                    path="/main"
                    render={() => (
                      <MainPage
                        error={this.state.error}
                        queryParams={this.props.queryParams}
                        categories={this.state.categories}
                      />
                    )}
                    exact
                  />
                  <Route
                    path="/settings/:group/:groupId"
                    render={props => (
                      <HomePage
                        {...props}
                        queryParams={this.props.queryParams}
                        categories={this.state.categories}
                        bearerToken={this.props.bearerToken}
                      />
                    )}
                  />
                  <Route path="*" component={ErrorPage} />
                </Switch>
              </main>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SettingsFrame;
