import React from "react";
import { array, func, object, string } from "prop-types";

class SubNavigation extends React.Component {
  static propTypes = {
    generateNav: func.isRequired,
    item: object.isRequired,
    urlPath: string,
    urlSegments: array.isRequired
  };

  state = {
    subNavExpanded: true
  };
  formatStringToUrl(string) {
    if (!string) return;
    return string
      .split(" ")
      .map(part => {
        return part.toLowerCase();
      })
      .join("-");
  }

  matchedItemInArray(array1, array2) {
    const ret = [];
    for (const item in array1) {
      if (array2.indexOf(array1[item]) > -1) {
        ret.push(array1[item]);
      }
    }
    return ret;
  }

  handleComponentSectionHeaderClick = () => {
    this.setState(prevState => {
      return { subNavExpanded: true };
    });
  };

  render() {
    const { generateNav, item, urlPath } = this.props;
    const { subNavExpanded } = this.state;

    return (
      <li key={item.header + "-subnav"} className="navigation__submenu">
        <input
          type="checkbox"
          id={item.header}
          checked={subNavExpanded}
          onChange={this.handleComponentSectionHeaderClick}
        />
        <ul>
          {generateNav(
            item.children,
            item.urlPath === undefined ? urlPath : item.urlPath
          )}
        </ul>
      </li>
    );
  }
}

export default SubNavigation;
