import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import Col from "@cx/ui/Col";

const LandingPage = props => {
  const { queryParams } = props;

  if (
    queryParams &&
    queryParams.dealerId > 0 &&
    queryParams.categoryName.length > 0
  )
    return <Redirect to={"/main"} />;

  return (
    <Col xs={4} xsOffset={3} className="error-message">
      <h1>Invalid parameters</h1>
    </Col>
  );
};

LandingPage.propTypes = {
  queryParams: PropTypes.object
};
export default LandingPage;
