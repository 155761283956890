import { toast } from "@cx/ui/Toast";
import orderBy from "lodash/orderBy";

const ignoreSettingsList = ["Vin Common Consumer Sync Toggle"];

export function displaySuccess(settingName) {
  toast.success(`${settingName} successfully saved.`, {
    autoClose: 2000
  });
}

export function displayError(errorResponse, settingName, group) {
  if (
    errorResponse &&
    errorResponse.data &&
    errorResponse.data.error &&
    errorResponse.data.error.message.includes(
      "duplicate setting value for the request sent"
    )
  )
    toast.error(
      `Only one ${settingName} may be toggled on per dealer. Please, toggle off ${group.toUpperCase()} ${settingName}.`,
      { autoClose: 8000 }
    );
  else {
    toast.error(`${errorResponse.status} ${errorResponse.statusText}`, {
      autoClose: 3000
    });
  }
}

export function getOrderedSettings(settings) {
  return orderBy(
    settings,
    function(setting) {
      return setting.name;
    },
    ["asc"]
  );
}

export function getSettingsOrderedByDisplayType(settings) {
  return orderBy(settings, ["displayType", "name"], ["desc", "asc"]);
}

export function getSettingsOrderedAndFiltered(settings) {
  const orderedSettings = orderBy(
    settings,
    ["displayType", "name"],
    ["desc", "asc"]
  );
  const filteredSettings = [];

  orderedSettings.forEach(setting => {
    if (!ignoreSettingsList.find(x => x === setting.displayName)) {
      filteredSettings.push(setting);
    }
  });

  return filteredSettings;
}

export function hasSensitiveSettings(settingNames) {
  return settingNames.filter(m => m.displayType === "sensitive").length > 0;
}

export function hasValue(object) {
  return object !== undefined && object !== null && object !== "";
}
