import React from "react";
import PropTypes from "prop-types";
import Switch from "react-toggle-switch";

export default class DealerSettingGroupBooleanType extends React.Component {
  static propTypes = {
    setting: PropTypes.object,
    updateSetting: PropTypes.func
  };

  updateSetting = event => {
    const eventToUpdate = {
      target: {
        value: "",
        name: this.props.setting.name
      }
    };
    if (this.props.setting.value === "true") {
      eventToUpdate.target.value = "false";
    } else {
      eventToUpdate.target.value = "true";
    }
    this.props.updateSetting(eventToUpdate);
  };
  render() {
    const isChecked = this.props.setting.value === "true";
    const { displayName } = this.props.setting;

    return (
      <React.Fragment>
        <div className={"cx-switch boolean-display"}>
          <label
            id={"SettingType-label-" + displayName}
            className="control-label"
          >
            {this.props.setting.displayName}
          </label>
          <br />
          <Switch onClick={this.updateSetting} on={isChecked} />
        </div>
      </React.Fragment>
    );
  }
}
