import React from "react";
import PropTypes from "prop-types";

class FormatComponentName extends React.Component {
  static propTypes = {
    name: PropTypes.string
  };

  static defaultProps = {
    name: ""
  };

  formatUrlToString = stringToFormat => {
    const string = stringToFormat.split("/")[0];
    let str =
      // make sentence case
      string.charAt(0).toUpperCase() +
      string
        .slice(1)
        // remove dashes
        .replace(/-/g, " ");
    // exceptions
    str = str
      // uppercase
      .replace(/cx|api|Vin/g, function(s) {
        return s.toUpperCase();
      });
    return str;
  };

  render() {
    const { name } = this.props;
    return <React.Fragment>{this.formatUrlToString(name)}</React.Fragment>;
  }
}

export default FormatComponentName;
