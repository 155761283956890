import React from "react";
import PropTypes from "prop-types";
import TextInput from "@cx/ui/TextInput";

export default class TextSettingType extends React.Component {
  static propTypes = {
    setting: PropTypes.object,
    settingName: PropTypes.string,
    updateSetting: PropTypes.func
  };

  state = {
    value: this.props.setting.value,
    isDisabled: true,
    setting: this.props.setting,
    settingName: this.props.settingName
  };

  updateSetting = event => {
    this.props.updateSetting(event);
  };

  render() {
    const { displayName } = this.props.setting;
    return (
      <div>
        <TextInput
          displayLabel={true}
          htmlId={"SettingType" + this.props.setting.name}
          name={this.props.setting.name}
          label={displayName}
          maxLength={50}
          onChange={this.updateSetting}
          value={this.props.setting.value}
        />
      </div>
    );
  }
}
