import React from "react";
import PropTypes from "prop-types";
import TextSettingType from "./TextSettingType";
import SensitiveSettingType from "./SensitiveSettingType";
import DealerSettingGroupBooleanType from "./DealerSettingGroupBooleanType";
import { SettingDisplayTypes } from "./SettingDisplayTypes";

export default class SettingTypeShell extends React.Component {
  static propTypes = {
    setting: PropTypes.object,
    settingName: PropTypes.string,
    toggleClearSettings: PropTypes.func,
    updateSetting: PropTypes.func
  };

  renderInput() {
    switch (this.props.setting.displayType) {
      case SettingDisplayTypes.TEXT:
        return <TextSettingType {...this.props} />;
      case SettingDisplayTypes.SENSITIVE:
        return <SensitiveSettingType {...this.props} />;
      case SettingDisplayTypes.DEALERSETTINGGROUPBOOLEAN:
        return <DealerSettingGroupBooleanType {...this.props} />;
      default:
        return "";
    }
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}
