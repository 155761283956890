let resolveFunc;

// Sends message to CarDashboard that we need an IDS Token
// CarDashboard will then respond to us with an IDS Token
export function receiveUserJWT() {
  window.parent.postMessage(
    {
      type: "GetIDSToken"
    },
    "*"
  );
  return new Promise(resolve => {
    resolveFunc = resolve;
    window.addEventListener("message", jctReceived);
  });
}

const jctReceived = event => {
  resolveFunc(event.data);
  window.removeEventListener("message", jctReceived);
};
