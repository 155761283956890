/* eslint-disable no-restricted-imports */

import http from "@cx/http";

export const devMode = RegExp("^local.*|127.0.0.1$", "i").test(
  window.location.hostname
);

export function getConfig(bearerToken) {
  return {
    baseUrls: {
      local: {
        app: "http://localhost",
        api: "http://localhost:64857"
      },
      local3000: {
        app: "http://localhost:3000",
        api: "https://dev-settings.vinsolutions.com"
      },
      dev: {
        app: "https://dev-settings-wfe.vinsolutions.com",
        api: "https://dev-settings.vinsolutions.com"
      },
      qa: {
        app: "https://qa-settings-wfe.vinsolutions.com",
        api: "https://qa-settings.vinsolutions.com"
      },
      qts: {
        app: "https://qts-settings-wfe.vinsolutions.com",
        api: "https://qts-settings.vinsolutions.com"
      },
      staging: {
        app: "https://staging-settings-wfe.vinsolutions.com",
        api: "https://staging-settings.vinsolutions.com"
      },
      production: {
        app: "https://settings-wfe.vinsolutions.com",
        api: "https://settings.vinsolutions.com"
      }
    },
    responseType: "json",
    headers: {
      Authorization: "Bearer " + bearerToken,
      Accept: "application/vnd.coxauto.v1+json",
      "Content-Type": "application/vnd.coxauto.v1+json",
      "Access-Control-Allow-Origin": "*"
    }
  };
}

export const settingsApiPost = function(url, data, bearerToken) {
  const { post } = http(getConfig(bearerToken));
  return post(url, data).catch(defaultErrorHandler);
};

export const settingsApiGet = function(url, bearerToken) {
  const { get } = http(getConfig(bearerToken));
  return get(url).catch(defaultErrorHandler);
};

export const settingsApiPut = function(url, data, bearerToken) {
  const { put } = http(getConfig(bearerToken));
  return put(url, data).catch(defaultErrorHandler);
};

function defaultErrorHandler(error) {
  return Promise.reject(error);
}
