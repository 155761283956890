/* eslint camelcase: "off" */
/* eslint-disable no-restricted-imports */

import http from "@cx/http";

export function getConfig(bearerToken) {
  return {
    baseUrls: {
      local3000: {
        app: "http://localhost:3000",
        api: "https://wsvc.qa.vinsolutions.com"
      },
      dev: {
        app: "https://dev-settings-wfe.vinsolutions.com",
        api: "https://wsvc.qa.vinsolutions.com"
      },
      qa: {
        app: "https://qa-settings-wfe.vinsolutions.com",
        api: "https://wsvc.qa.vinsolutions.com"
      },
      qts: {
        app: "https://qts-settings-wfe.vinsolutions.com",
        api: "https://qts-wsvc.vinsolutions.com"
      },
      staging: {
        app: "https://staging-settings-wfe.vinsolutions.com",
        api: "https://staging-wsvc.vinsolutions.com"
      },
      prod: {
        app: "https://settings-wfe.vinsolutions.com",
        api: "https://wsvc.vinsolutions.com"
      }
    },
    responseType: "json",
    withCredentials: false,
    headers: {
      Authorization: "Bearer " + bearerToken,
      Accept: "application/json",
      api_key: "settings"
    }
  };
}

export const gatewayApiGet = function(url, bearerToken) {
  const { get } = http(getConfig(bearerToken));
  return get(url).catch(defaultErrorHandler);
};

function defaultErrorHandler(error) {
  return Promise.reject(error);
}
