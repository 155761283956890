function getSettingGroups(settingGroups) {
  return settingGroups.map(function(group) {
    return group.displayName + "/" + group.id;
  });
}

export function getNavigationLinks(categories) {
  if (categories.length > 0) {
    return categories.map(function(category) {
      return {
        header: category.categoryDisplayName,
        urlPath: "/settings/",
        children: getSettingGroups(category.settingGroups)
      };
    });
  }
}
