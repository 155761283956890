import React from "react";
import { func, object, array } from "prop-types";
import { NavLink } from "react-router-dom";
import { getNavigationLinks } from "../utils/navigationHelper";
import SubNavigation from "./SubNavigation";
import FormatComponentName from "./FormatComponentName";

class Navigation extends React.Component {
  static propTypes = {
    categories: array,
    location: object.isRequired,
    toggleActiveNavigation: func.isRequired
  };

  state = {
    filter: ""
  };

  toggleActive = () => {
    this.props.toggleActiveNavigation();
  };

  getNav = filter => {
    const links = getNavigationLinks(this.props.categories);
    if (!filter) return links;
    return links
      .reduce((newArray, item) => {
        if (typeof item === "string") {
          return newArray.push(item) && newArray;
        } else {
          return newArray.push(...item.children) && newArray;
        }
      }, [])
      .filter(name => name.toUpperCase().includes(filter.toUpperCase()))
      .sort();
  };

  generateNav = (items, urlPath) => {
    const urlSegments = this.props.location.pathname.split("/");
    if (items) {
      return items.map((item, index) => {
        const uniqueKey = index + 1;
        if (typeof item === "object") {
          return (
            <SubNavigation
              key={item.header + uniqueKey + "-subnav"}
              generateNav={this.generateNav}
              urlSegments={urlSegments}
              item={item}
              urlPath={urlPath}
            />
          );
        } else if (typeof item === "string") {
          return (
            <li key={item}>
              <NavLink to={urlPath + item} onClick={this.toggleActive}>
                <FormatComponentName name={item} />
              </NavLink>
            </li>
          );
        }
        return "";
      });
    }
  };

  render() {
    const nav = this.getNav(this.state.filter);
    return (
      <div className="navigation">
        <div className="navigation__nav">
          <ul>{this.generateNav(nav)}</ul>
        </div>
      </div>
    );
  }
}

export default Navigation;
