import React from "react";
import PropTypes from "prop-types";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import SettingTypeShell from "../components/settingTypes/SettingTypeShell";
import objectAssign from "object-assign";
import { createUserSetting, updateUserSetting } from "../api/settingsApi";
import Col from "@cx/ui/Col";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import {
  displayError,
  displaySuccess,
  getOrderedSettings
} from "./utils/commonHelper";

export default class UserSettingsCard extends React.Component {
  static propTypes = {
    bearerToken: PropTypes.string,
    queryParams: PropTypes.object,
    selectedUser: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedUser: this.props.selectedUser,
      userSettings: this.props.selectedUser.settings,
      isLoading: false,
      disableSave: true
    };
  }

  toggleLoading = toggle => {
    this.setState({ isLoading: toggle, disableSave: true });
  };

  saveUserSettings = () => {
    const { userSettings } = this.state;
    const modifiedSettings = userSettings.filter(
      setting => setting.hasModified === true
    );
    if (modifiedSettings.length > 0) {
      const updatedSettings = modifiedSettings.map(function(userSetting) {
        if (userSetting.settingId > 0) {
          this.toggleLoading(true);
          Promise.resolve(
            updateUserSetting(
              userSetting,
              this.props.queryParams,
              this.props.bearerToken
            )
          )
            .then(() => {
              displaySuccess(userSetting.displayName);
              userSetting.hasModified = false;
              this.toggleLoading(false);
              return userSetting;
            })
            .catch(error => {
              displayError(error);
            });
        } else {
          this.toggleLoading(true);
          Promise.resolve(
            createUserSetting(
              userSetting,
              this.props.queryParams,
              this.props.bearerToken
            )
          )
            .then(response => {
              displaySuccess(userSetting.displayName);
              const locationHref = response.headers.location;
              userSetting.hasModified = false;
              userSetting.settingId = locationHref.substring(
                locationHref.lastIndexOf("/") + 1
              );
              this.toggleLoading(false);
              return userSetting;
            })
            .catch(error => {
              displayError(error);
            });
        }
        return userSetting;
      }, this);

      const resetSettings = userSettings
        .filter(setting => {
          return setting.hasModified === false;
        })
        .concat(updatedSettings);
      this.setState({ userSettings: getOrderedSettings(resetSettings) });
    }
  };

  onSettingChange = event => {
    const existingSetting = this.state.userSettings.find(
      m => m.name === event.target.name
    );
    if (existingSetting.value !== event.target.value) {
      const updatedUserSettings = this.state.userSettings.map(function(
        userSetting
      ) {
        if (userSetting.name === event.target.name) {
          userSetting.value = event.target.value;
          userSetting.hasModified = true;
        }
        return userSetting;
      });
      this.setState({
        selectedUser: objectAssign({}, this.props.selectedUser, {
          settings: updatedUserSettings
        }),
        disableSave: false
      });
    }
  };

  settingsToDisplay() {
    const { selectedUser } = this.state,
      { onSettingChange } = this;
    return selectedUser.settings.map(function(userSetting) {
      return (
        <Col sm={4} md={4} key={userSetting.name}>
          <SettingTypeShell
            setting={userSetting}
            key={userSetting.name}
            updateSetting={onSettingChange}
            settingType={"user"}
          />
        </Col>
      );
    });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div>
        <Grid htmlId="userSettingsGrid">
          <Row>
            {isLoading ? (
              <LoadingIndicator htmlId="userSettingLoadingIndicator" />
            ) : (
              this.settingsToDisplay()
            )}
          </Row>
          <div className="pull-right">
            <Button
              size="sm"
              style={{ marginLeft: "15px" }}
              buttonStyle="primary"
              className="submit-user"
              htmlId="submitButtonUser"
              onClick={this.saveUserSettings}
              disabled={this.state.disableSave}
            >
              Save
            </Button>
          </div>
        </Grid>
      </div>
    );
  }
}
