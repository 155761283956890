import React from "react";
import PropTypes from "prop-types";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import { Redirect } from "react-router";
import { getSettingGroupId } from "../utils/settingHelper";

const MainPage = props => {
  const showError = (
    <Col xs={4} xsOffset={3}>
      <h4>{props.error}</h4>
    </Col>
  );

  if (props.queryParams.groupName.length > 0 && props.categories.length > 0) {
    const url =
      "/settings/" +
      props.queryParams.groupName.toString() +
      "/" +
      getSettingGroupId(props.queryParams.groupName, props.categories);
    return <Redirect to={url} />;
  }

  return (
    <div className="mainPage error-message">
      <Row>{props.error ? showError : null}</Row>
    </div>
  );
};
MainPage.propTypes = {
  categories: PropTypes.array,
  error: PropTypes.string,
  queryParams: PropTypes.object
};
export default MainPage;
