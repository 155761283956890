import React from "react";
import PropTypes from "prop-types";

export const ErrorPage = props => {
  const { message } = props;

  return (
    <div className="error-message">
      <h1>404 Not Found {message}</h1>
    </div>
  );
};

ErrorPage.propTypes = {
  message: PropTypes.string
};
