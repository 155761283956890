import React from "react";
import PropTypes from "prop-types";
import Table from "@cx/ui/Table";
import Button from "@cx/ui/Button";
import { getUserSettingColumns } from "./utils/mapperHelper";
import UserSettingsCard from "./UserSettingsCard";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

export default class UserSettings extends React.Component {
  static propTypes = {
    bearerToken: PropTypes.string,
    group: PropTypes.string,
    hasSensitiveSettings: PropTypes.bool,
    queryParams: PropTypes.object,
    settingGroupId: PropTypes.string,
    toggleClearSettings: PropTypes.func,
    userSettings: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      userSettings: this.props.userSettings,
      toggle: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.userSettings !== prevProps.userSettings) {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({ userSettings: this.props.userSettings });
  }

  onShowUserComponent = user => {
    return (
      <span>
        <UserSettingsCard
          selectedUser={user}
          queryParams={this.props.queryParams}
          bearerToken={this.props.bearerToken}
        />
      </span>
    );
  };

  onToggleClick = () => {
    this.props.toggleClearSettings(
      "user",
      this.props.settingGroupId,
      !this.state.toggle
    );
    this.setState({ toggle: !this.state.toggle, userSettings: [] });
  };

  render() {
    const { userSettings } = this.state;
    const columns = getUserSettingColumns();

    return (
      <div>
        <div id="user-settings-header">
          <strong>User Settings</strong>
          {this.props.hasSensitiveSettings ? (
            <Button
              buttonStyle="link"
              size="sm"
              htmlId="dealerSettingsClearToggle"
              onClick={this.onToggleClick}
            >
              {this.state.toggle ? "Hide" : "Show"}
            </Button>
          ) : (
            ""
          )}
        </div>
        <br />
        <div>
          {userSettings.length > 0 ? (
            <Table
              htmlId="userSettingsTable"
              data={userSettings}
              dataDensity="comfortable"
              columns={columns}
              expandedRowComponent={this.onShowUserComponent}
              pageSize={20}
              sortableColumns={true}
              displayFilter={true}
            />
          ) : (
            <LoadingIndicator htmlId="userSettingsLoadingIndicator" />
          )}
        </div>
      </div>
    );
  }
}
