import React from "react";
import PropTypes from "prop-types";
import TextInput from "@cx/ui/TextInput";

export default class SensitiveSettingType extends React.Component {
  static propTypes = {
    setting: PropTypes.object,
    settingName: PropTypes.string,
    settingType: PropTypes.string,
    toggleClearSettings: PropTypes.func,
    updateSetting: PropTypes.func
  };

  state = {
    value: "",
    isDisabled: true,
    setting: this.props.setting,
    settingName: this.props.settingName,
    toggleSetting: true
  };

  updateSetting = event => {
    this.props.updateSetting(event);
  };

  render() {
    const { displayName } = this.props.setting,
      readOnly = this.props.setting.value.indexOf("*") !== -1;

    return (
      <div>
        {" "}
        <TextInput
          readOnly={readOnly}
          htmlId={"SettingType" + this.props.setting.name}
          label={displayName}
          name={this.props.setting.name}
          maxLength={50}
          onChange={this.updateSetting}
          value={this.props.setting.value}
        />
      </div>
    );
  }
}
