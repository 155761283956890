import React from "react";
import PropTypes from "prop-types";
import {
  createDealerSetting,
  getCurrentDealerGroupBooleanSetting,
  updateDealerSetting
} from "../api/settingsApi";
import Button from "@cx/ui/Button";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import SettingTypeShell from "../components/settingTypes/SettingTypeShell";
import Col from "@cx/ui/Col";
import {
  displayError,
  displaySuccess,
  getOrderedSettings,
  getSettingsOrderedAndFiltered
} from "./utils/commonHelper";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { SettingDisplayTypes } from "./settingTypes/SettingDisplayTypes";

export default class DealerSettings extends React.Component {
  static propTypes = {
    bearerToken: PropTypes.string,
    dealerSettings: PropTypes.array,
    group: PropTypes.string,
    hasSensitiveSettings: PropTypes.bool,
    hideLeftNav: PropTypes.bool,
    queryParams: PropTypes.object,
    refreshDealerSettings: PropTypes.func,
    settingGroupId: PropTypes.string,
    toggleClearSettings: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      dealerSettings: this.props.dealerSettings,
      disableSave: true,
      isLoading: false,
      toggle: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dealerSettings !== prevProps.dealerSettings) {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({ dealerSettings: this.props.dealerSettings });
  }

  onToggleClick = () => {
    this.props.toggleClearSettings(
      "dealer",
      this.props.settingGroupId,
      !this.state.toggle
    );
    this.setState({ toggle: !this.state.toggle, dealerSettings: [] });
  };

  toggleLoading = toggle => {
    this.setState({ isLoading: toggle, disableSave: true });
  };

  saveDealerSettings = () => {
    const { dealerSettings } = this.state;
    const modifiedSettings = dealerSettings.filter(setting => {
      return setting.hasModified === true;
    });

    const updatedSettings = modifiedSettings.map(function(modifiedSetting) {
      if (modifiedSetting.settingId > 0) {
        this.toggleLoading(true);
        updateDealerSetting(
          modifiedSetting,
          this.props.queryParams,
          this.props.bearerToken
        )
          .then(() => {
            displaySuccess(modifiedSetting.displayName);
            this.toggleLoading(false);
            modifiedSetting.hasModified = false;
            return modifiedSetting;
          })
          .catch(error => {
            this.handleError(error, modifiedSetting);
          });
      } else {
        this.toggleLoading(true);
        createDealerSetting(
          modifiedSetting,
          this.props.queryParams,
          this.props.bearerToken
        )
          .then(response => {
            displaySuccess(modifiedSetting.displayName);
            this.toggleLoading(false);
            const locationHref = response.headers.location;
            modifiedSetting.hasModified = false;
            modifiedSetting.settingId = locationHref.substring(
              locationHref.lastIndexOf("/") + 1
            );
            return modifiedSetting;
          })
          .catch(error => {
            this.handleError(error, modifiedSetting);
          });
      }
      return modifiedSetting;
    }, this);
    const resetSettings = dealerSettings
      .filter(setting => {
        return setting.hasModified === false;
      })
      .concat(updatedSettings);
    this.setState({
      dealerSettings: getOrderedSettings(resetSettings),
      disableSave: true
    });
  };

  onSettingChange = event => {
    const existingSetting = this.props.dealerSettings.find(
      m => m.name === event.target.name
    );
    if (existingSetting.value !== event.target.value) {
      const updatedDealerSettings = this.state.dealerSettings.map(function(
        dealerSetting
      ) {
        if (dealerSetting.name === event.target.name) {
          dealerSetting.value = event.target.value;
          dealerSetting.hasModified = true;
        }
        return dealerSetting;
      });
      this.setState({
        dealerSettings: updatedDealerSettings,
        disableSave: false
      });
    }
  };

  settingsToDisplay() {
    const { dealerSettings } = this.state,
      { onSettingChange } = this;
    const orderedFilteredDealerSettings = getSettingsOrderedAndFiltered(
      dealerSettings
    );

    return orderedFilteredDealerSettings.map(function(setting) {
      return (
        <span key={setting.name}>
          <Col md={4}>
            <SettingTypeShell
              setting={setting}
              key={setting.name}
              updateSetting={onSettingChange}
            />
          </Col>
        </span>
      );
    });
  }

  handleError(error, modifiedSetting) {
    if (
      modifiedSetting.displayType ===
      SettingDisplayTypes.DEALERSETTINGGROUPBOOLEAN
    ) {
      getCurrentDealerGroupBooleanSetting(
        modifiedSetting.name,
        modifiedSetting.value,
        this.props.queryParams,
        this.props.bearerToken
      )
        .then(response => {
          const group = response.data.items[0].group;
          displayError(error.response, modifiedSetting.displayName, group);
          this.props.refreshDealerSettings();
          this.toggleLoading(false);
        })
        .catch(error => {});
    } else {
      displayError(error.response);
      this.props.refreshDealerSettings();
      this.toggleLoading(false);
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="show-grid">
        <Grid htmlId="dealerSettingsGrid">
          <Row>
            {this.props.hideLeftNav ? (
              <div id="header-no-padding">{this.props.group}</div>
            ) : (
              <div id="header">{this.props.group}</div>
            )}
            <div id="section-header">
              <strong>Dealer Settings</strong>
              {}
              {this.props.hasSensitiveSettings ? (
                <Button
                  buttonStyle="link"
                  size="sm"
                  htmlId="dealerSettingsClearToggle"
                  onClick={this.onToggleClick}
                >
                  {this.state.toggle ? "Hide" : "Show"}
                </Button>
              ) : (
                ""
              )}
            </div>
            {isLoading ? (
              <LoadingIndicator htmlId="userSettingLoadingIndicator" />
            ) : (
              this.settingsToDisplay()
            )}{" "}
          </Row>
          <Row>
            <div className="pull-right">
              <Button
                style={{ marginLeft: "15px" }}
                buttonStyle="primary"
                htmlId="dealerSettingsSaveButton"
                onClick={this.saveDealerSettings}
                disabled={this.state.disableSave}
              >
                Save
              </Button>
            </div>
          </Row>
        </Grid>
        <hr />
      </div>
    );
  }
}
