import React, { Fragment } from "react";
import Navigation from "./Navigation";

class ResponsiveAside extends React.Component {
  state = {
    isActive: false
  };

  toggleActiveNavigation = () => {
    this.setState(prevState => {
      return {
        isActive: !prevState.isActive
      };
    });
  };

  render() {
    const { props } = this;
    return (
      <Fragment>
        <aside className={this.state.isActive ? "active" : ""}>
          <Navigation
            {...props}
            toggleActiveNavigation={this.toggleActiveNavigation}
          />
        </aside>
      </Fragment>
    );
  }
}

export default ResponsiveAside;
