import React from "react";
import { Route, Switch } from "react-router-dom";
import { ErrorPage } from "./components/pages/ErrorPage";
import qs from "qs";
import MainFrame from "./components/navigation/MainFrame";
import { getMockData } from "../data/mock-data";
import { devMode } from "./api/api-config-settings";

const queryParamsLower = qs.parse(
  window.location.search.substring(1).toLowerCase()
);
const queryParams = qs.parse(window.location.search.substring(1));

const sanitizedQueryParams = devMode ? getMockData() : {};

if (!devMode) {
  sanitizedQueryParams.dealerId = Number(queryParamsLower.dealerid || 0);
  sanitizedQueryParams.categoryName = queryParamsLower.categoryname || "";
  sanitizedQueryParams.isLocal = Boolean(queryParamsLower.islocal || false);
  sanitizedQueryParams.bearerToken = queryParams.bearerToken;
  sanitizedQueryParams.groupName = queryParamsLower.groupname || "";
}
export default (
  <Switch>
    <Route
      render={props => (
        <MainFrame {...props} queryParams={sanitizedQueryParams} />
      )}
    />
    <Route path="/*" component={ErrorPage} />
  </Switch>
);
